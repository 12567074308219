import { Button, DatePicker, EditButton, Form, SaveButton, Space, Table, TimePicker } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import dayjs from 'dayjs'
import { definitions } from 'interfaces'
import { useState } from 'react'
import { formatDateTime, formatTime, updateEmployeeTime } from 'utility'

type EmployeeTimesProps = {
  user: definitions['users'] | undefined
  EmployeeTimesEditableTable: any
}

export const EmployeeTimes: React.FC<EmployeeTimesProps> = ({ EmployeeTimesEditableTable }) => {
  const t = useTranslate()
  const { open } = useNotification()
  const [selectedStartDate, setSelectedStartDate] = useState<dayjs.Dayjs>()
  const [selectedStartTime, setSelectedStartTime] = useState<dayjs.Dayjs>()
  const [selectedEndDate, setSelectedEndDate] = useState<dayjs.Dayjs>()
  const [selectedEndTime, setSelectedEndTime] = useState<dayjs.Dayjs>()

  const createDate = async (selectedDate: any, selectedTime: any, defaultDate: any) => {
    const finalDate = new Date(
      selectedDate !== undefined ? selectedDate.$d.getFullYear() : defaultDate.getFullYear(),
      selectedDate !== undefined ? selectedDate.$d.getMonth() : defaultDate.getMonth(),
      selectedDate !== undefined ? selectedDate.$d.getDate() : defaultDate.getDate(),
      selectedTime !== undefined ? selectedTime.$d.getHours() : defaultDate.getHours(),
      selectedTime !== undefined ? selectedTime.$d.getMinutes() : defaultDate.getMinutes()
    )
    return finalDate
  }

  const {
    tableProps,
    tableQueryResult: { refetch },
    formProps,
    isEditing,
    setId: setEditId,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps
  } = EmployeeTimesEditableTable

  const handleSave = async (record: definitions['employee_times']) => {
    const dateStart = new Date(record.date_start)
    const dateEnd = new Date(record.date_end!)

    const clockInStartDate = await createDate(selectedStartDate, selectedStartTime, dateStart)
    const clockInEndDate = await createDate(selectedEndDate, selectedEndTime, dateEnd)
    const data = await updateEmployeeTime(record.id, clockInStartDate, clockInEndDate)

    if (data) {
      open?.({
        type: 'success',
        message: t('employee_times.successMessage.description'),
        description: t('employee_times.successMessage.title')
      })
      refetch()
      setEditId(undefined)
    }
  }

  return (
    <div>
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD' && record.date_end) {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column
            key="date_start"
            dataIndex="date_start"
            title={t('employee_times.fields.date_start')}
            align='center'
            render={(value, data: definitions['employee_times']) => {
              if (isEditing(data.id)) {
                return (
                  <DatePicker style={{ width: 91 }} defaultValue={dayjs(value)} onChange={(e: any) => { setSelectedStartDate(e) }}/>
                )
              }
              return formatDateTime(value, true)
            }}
          />
          <Table.Column
            key="time_start"
            dataIndex="date_start"
            width={50}
            title={t('employee_times.fields.time_start')}
            align='center'
            render={(value, data: definitions['employee_times']) => {
              if (isEditing(data.id)) {
                return (
                    <TimePicker style={{ width: 80 }} format={'HH:mm'} defaultValue={dayjs(dayjs(value), 'HH:MM')} onChange={(e: any) => setSelectedStartTime(e)}/>
                )
              }
              return formatTime(value)
            }}
          />
          <Table.Column
            key="date_end"
            dataIndex="date_end"
            title={t('employee_times.fields.date_end')}
            align='center'
            render={(value, data: definitions['employee_times']) => {
              if (isEditing(data.id)) {
                return (
                    <DatePicker style={{ width: 91 }} defaultValue={dayjs(value)} onChange={(e: any) => setSelectedEndDate(e)}/>
                )
              }
              return formatDateTime(value, true)
            }}
          />
          <Table.Column
            key="time_end"
            dataIndex="date_end"
            width={50}
            title={t('employee_times.fields.time_end')}
            align='center'
            render={(value, data: definitions['employee_times']) => {
              if (isEditing(data.id)) {
                return (
                  <TimePicker style={{ width: 80 }} format={'HH:mm'} defaultValue={dayjs(dayjs(value), 'HH:MM')} onChange={(e: any) => setSelectedEndTime(e)}/>
                )
              }
              return formatTime(value)
            }}
          />
          <Table.Column
            key="total"
            dataIndex="total"
            title={t('employee_times.fields.duration')}
            align='center'
            render={(value) => {
              const hours = Math.floor(value / (1000 * 60 * 60))
              const minutes = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60))
              const seconds = Math.floor((value % (1000 * 60)) / 1000)
              return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
            }}
          />
          <Table.Column<definitions['employee_times']>
            title={t('table.actions')}
            dataIndex="actions"
            align="center"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      hideText
                      size="small"
                      onClick={() => handleSave(record)}
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </Space>
                )
              }
              return (
                <>
                  <Space>
                    <EditButton
                      {...editButtonProps(record.id)}
                      hideText
                      disabled={!record.date_end}
                      size="small"
                    />
                  </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
    </div>
  )
}
