import { HttpError, IResourceComponentsProps, ResourceRouterParams, useRouterContext } from '@pankod/refine-core'

import {
  Show,
  useModal,
  // Button,
  Card,
  Row,
  Col,
  useEditableTable
} from '@pankod/refine-antd'

import { definitions } from 'interfaces'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { getEmployeeData, getLastEntryData, upsertEmployeeTime } from 'utility/supabaseApi'
import { getEmployeeData } from 'utility/supabaseApi'
// import { UserMenuContent } from 'components/header/UserMenuContent'
import { EmployeeTimes } from 'components/profile/EmployeeTimes'
import { CreateAbsentModal } from 'components/profile/CreateAbsentModal'
// import { EmployeeAbsent } from 'components/profile/EmployeeAbsent'
import { ClockInCalendar } from './ClockInCalendar'
// import ProfileCalendar from 'components/profile/ProfileCalendar'

type EmployeeData = {
  user: definitions['users']
}

export const ProfileShow: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation()
  const { modalProps, close } = useModal()

  const { useParams } = useRouterContext()
  const { id: userId } = useParams<ResourceRouterParams>()

  const [employeeData, setEmployeeData] = useState<EmployeeData>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // const [entryData, setEntryData] = useState<definitions['employee_times'] | null>(null)
  // const [note, setNote] = useState<string | undefined>(undefined)

  // useEffect(() => {
  //   if (employeeData?.user?.id) getEmployeeLastTime()
  // }, [employeeData])

  // const getEmployeeLastTime = async () => {
  //   if (employeeData?.user?.id) {
  //     const data = await getLastEntryData(employeeData?.user?.id)
  //     if (data) {
  //       setEntryData(data)
  //     }
  //   }
  // }

  const getEmployeeDataFromApi = async () => {
    const { user } = await getEmployeeData((userId) ? parseInt(userId) : 0)
    setIsLoading(false)
    setEmployeeData({ user })
  }

  useEffect(() => {
    getEmployeeDataFromApi()
  }, [])

  // const registryEmployeeData = async () => {
  //   if (employeeData?.user?.id) {
  //     let data: any
  //     if (entryData && entryData.date_start) {
  //       data = await upsertEmployeeTime(employeeData?.user?.id, new Date(entryData?.date_start), note, entryData.id)
  //     } else {
  //       data = await upsertEmployeeTime(employeeData?.user?.id)
  //     }
  //     if (data) {
  //       if (data.date_end) setEntryData(null)
  //       else setEntryData(data)
  //     }
  //   }
  // }

  const EmployeeTimesEditableTable = useEditableTable<definitions['employee_times'], HttpError>({
    resource: 'employee_times',
    initialSorter: [
      {
        field: 'date_start',
        order: 'desc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'user_id',
        operator: 'eq',
        value: employeeData?.user.id
      }
    ],
    initialPageSize: 8,
    syncWithLocation: false,
    metaData: {
      select: '*'
    }
  })

  return (<>
    <Row gutter={[8, 8]}>
      <Col xl={12}>
        <Card style={{ height: '100%' }}>
          <Show
            isLoading={isLoading}
            breadcrumb={false}
            goBack={false}
            headerProps={{
              title: t('profile.title'),
              extra: (
                <>
                </>
              )
            }}
          >
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div style={{ width: '100%' }}>
                {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <UserMenuContent
                    user={employeeData?.user}
                    registryEmployeeData={registryEmployeeData}
                    entryData={entryData}
                    note={note}
                    setNote={setNote}
                    showProfileLink={false}
                  />
                </div> */}
                <div style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column', gap: '1rem' }}>
                  <EmployeeTimes user={employeeData?.user} EmployeeTimesEditableTable={EmployeeTimesEditableTable} />
                </div>
              </div>
              {/* <div style={{ width: '40%' }}>
                <ProfileCalendar /> TODO: fix Calendar to best info showing
              </div> */}
            </div>
            <CreateAbsentModal user={employeeData?.user} modalProps={modalProps} close={close} />
          </Show>
        </Card>
      </Col>
      <Col xl={12}>
        <Card>
          <ClockInCalendar user={employeeData?.user} EmployeeTimesEditableTable={EmployeeTimesEditableTable}/>
        </Card>
      </Col>
      {/* <Col xl={24}>
        <Card>
        <Show
            isLoading={isLoading}
            breadcrumb={false}
            goBack={false}
            headerProps={{
              title: t('profile.absenceRegister'),
              extra: (
                <>
                  <Button onClick={() => show()}>
                    {t('profile.createAbsent')}
                  </Button>
                </>
              )
            }}
          >
            <EmployeeAbsent user={employeeData?.user} />
        </Show>
        </Card>
      </Col> */}
    </Row>
  </>
  )
}
