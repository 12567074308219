import { Calendar, useModal } from '@pankod/refine-antd'
import { ClockInModal } from './ClockInModal'
import { useEffect, useState } from 'react'
import { getLastEntryData } from 'utility'
import dayjs from 'dayjs'

export const ClockInCalendar = ({ user, EmployeeTimesEditableTable }: any) => {
  const { modalProps, show, close } = useModal()
  const [selectedDate, setSelectedDate] = useState<any>('0000-00-00')

  const getEmployeeLastTime = async () => {
    if (user?.id) {
      const data = await getLastEntryData(user?.id)
      if (data) {
        setSelectedDate(dayjs(dayjs(data.date_start).format('YYYY-MM-DD')))
      }
    }
  }

  useEffect(() => {
    getEmployeeLastTime()
  }, [user])

  const disabledDate = (current: any) => {
    if (selectedDate) {
      return current.isBefore(selectedDate, 'day')
    }
  }

  const showModal = (e: any) => {
    show()
    setSelectedDate(e)
  }
  return <div>
    <Calendar className="custom-calendar" style={{ marginTop: 33 }} mode={'month'} disabledDate={disabledDate} onSelect={(e: any) => { showModal(e) }}/>
    <ClockInModal modalProps={modalProps} close={close} selectedDate={selectedDate} setSelectedDate={setSelectedDate} user={user} EmployeeTimesEditableTable={EmployeeTimesEditableTable}/>
    </div>
}
