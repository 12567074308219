import { Avatar, Popover } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { useEffect, useState } from 'react'
import { getLastEntryData } from 'utility'
// import { definitions } from 'interfaces'
// import { useEffect, useState } from 'react'
// import { getLastEntryData, upsertEmployeeTime } from 'utility'
// import { UserMenuContent } from './UserMenuContent'

type UserMenuProps = {
  user: any
}

const UserMenu: React.FC<UserMenuProps> = ({ user }) => {
  const t = useTranslate()
  // let dataLastEntry: any
  const [entryData, setEntryData] = useState<definitions['employee_times'] | null>(null)
  // const [note, setNote] = useState<string | undefined>(undefined)

  // useEffect(() => {
  //   if (user?.user_metadata?.id) getEmployeeLastTime()
  // }, [user])

  // const getEmployeeLastTime = async () => {
  //   const data = await getLastEntryData(user?.user_metadata?.id)
  //   if (data) {
  //     setEntryData(data)
  //   }
  // }

  // const registryEmployeeData = async () => {
  //   let data: any
  //   if (entryData && entryData.date_start) {
  //     data = await upsertEmployeeTime(user?.user_metadata?.id, null, new Date(entryData?.date_start), note, entryData.id)
  //   } else {
  //     data = await upsertEmployeeTime(user?.user_metadata?.id)
  //   }
  //   if (data) {
  //     if (data.date_end) setEntryData(null)
  //     else setEntryData(data)
  //   }
  // }

  const getEmployeeLastTime = async () => {
    if (user?.user_metadata?.id) {
      const data = await getLastEntryData(user?.user_metadata?.id)
      if (data) {
        setEntryData(data)
      }
    }
  }

  useEffect(() => {
    getEmployeeLastTime()
  }, [user?.user_metadata])

  return <Popover placement="bottomRight" content={
      <div>
        {entryData?.date_start
          ? <p>{t('profile.inTime', {
            day: new Date(entryData.date_start).getDate(),
            month: new Date(entryData.date_start).getMonth() + 1,
            year: new Date(entryData.date_start).getFullYear(),
            hours: new Date(entryData.date_start).getHours(),
            mins: new Date(entryData.date_start).getMinutes()
          })}</p>
          : <p>{t('profile.noTime')}</p>}
        <a style={{ height: 56 }} href={`/profile/show/${user?.user_metadata?.id}`}>{t('profile.goToProfile')}</a>
      </div>
    }>
    {/* <Popover placement="bottomRight" content={UserMenuContent({ user, registryEmployeeData, entryData, note, setNote, showProfileLink: true })}> */}
    <Avatar size="large" src={user?.user_metadata?.avatar_url} alt={user?.name} />
  </Popover>
}

export default UserMenu
