import { Modal, ModalProps } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { UserMenuContent } from 'components/header/UserMenuContent'
import dayjs from 'dayjs'
import { definitions } from 'interfaces'
import { useEffect, useState } from 'react'
import { getLastEntryData, upsertEmployeeTime } from 'utility'

type ClockInModalProps = {
  modalProps: ModalProps;
  close: any;
  selectedDate: any;
  user: definitions['users']
  EmployeeTimesEditableTable: any
  setSelectedDate: any
};

export const ClockInModal: React.FC<ClockInModalProps> = ({
  modalProps,
  close,
  selectedDate,
  user,
  EmployeeTimesEditableTable,
  setSelectedDate
}) => {
  const t = useTranslate()
  const [entryData, setEntryData] = useState<definitions['employee_times'] | null>(null)
  const [note, setNote] = useState<string | undefined>(undefined)
  const [seletedHour, setSelectedHour] = useState<any>()
  const date = selectedDate === '0000-00-00' ? '' : selectedDate.format('DD-MM-YYYY')

  const { tableQueryResult: { refetch } } = EmployeeTimesEditableTable

  useEffect(() => {
    if (user?.id) getEmployeeLastTime()
  }, [user?.id])

  const getEmployeeLastTime = async () => {
    if (user?.id) {
      const data = await getLastEntryData(user?.id)
      if (data) {
        setEntryData(data)
      }
    }
  }

  const registryEmployeeData = async () => {
    if (user?.id) {
      let data: any
      const clockInDate = new Date(
        selectedDate.$d.getFullYear(),
        selectedDate.$d.getMonth(),
        selectedDate.$d.getDate(),
        seletedHour.$d.getHours(),
        seletedHour.$d.getMinutes()
      )
      if (entryData && entryData.date_start) {
        data = await upsertEmployeeTime(user?.id, clockInDate, new Date(entryData?.date_start), note, entryData.id)
      } else {
        data = await upsertEmployeeTime(user?.id, clockInDate)
      }
      if (data) {
        if (data.date_end) setEntryData(null)
        else setEntryData(data)
      }
      refetch()
    }
  }

  const handleClose = () => {
    if (entryData?.date_start) {
      setSelectedDate(dayjs(dayjs(entryData.date_start).format('YYYY-MM-DD')))
    } else {
      setSelectedDate('0000-00-00')
    }
    close()
  }

  return (
    <Modal
      {...modalProps}
      title={t('profile.clockIn', { date: date })}
      centered
      onOk={() => handleClose()}
      onCancel={() => handleClose()}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <UserMenuContent
          user={user}
          registryEmployeeData={registryEmployeeData}
          entryData={entryData}
          note={note}
          setNote={setNote}
          showProfileLink={false}
          setSelectedHour = {setSelectedHour}
          selectedHour = {seletedHour}
        />
      </div>
    </Modal>
  )
}
